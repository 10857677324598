import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { pastExamServiceGetPastExam, pastExamServicePutPastExam } from "../../api/past_exam";

import LoadingScreen from "../../components/LoadingScreen";
import ErrorContent from "../../components/ErrorContent";
import { academicYear, examStatus, questionOption } from "../../api/types";
import { AuthContext } from "../../contexts/AuthContext";

function ExamPreview() {
    let { id } = useParams();
    let authContext = React.useContext(AuthContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [exam, setExam] = useState(null);
    const [fileInfo, setFileInfo] = useState({});


    const [previewPDF, setPreviewPDF] = useState(null);






    const loadData = async () => {
        try {
            const result = await pastExamServiceGetPastExam(id)
            const object = result.toObject();
            setExam(object.pastExam);
            setFileInfo(object.fileInfo);
            setPreviewPDF(`${process.env.REACT_APP_FMA_URL}/booklet/${object.pastExam.bookletId}.pdf`);
            setLoading(false);
        } catch (error) {

            alert(error.message);

            setError(error)
            setLoading(false);
        }
    }


    const onPressChangeStatus = async (status) => {
        setLoading(true);

        exam.status = status;
        await pastExamServicePutPastExam(exam);
        navigate(`/exams?areaId=${exam.area.id}`);


        // await examServicePutExam(
        //     exam.id,
        //     status,
        //     exam.questionsList,
        //     false,
        //     false
        // );
        // const result = await listExamsApi(examStatus().EXAM_STATUS_PENDING);
        // const exams = result.toObject().examsList;
        // if (exams.length) {
        //     navigate(`/exams/${exams.pop().id}/preview`);
        //     navigate(0);
        // } else {
        //     navigate(`/exams`);
        // }
    }
    const onPressPending = async () => {
        setLoading(true);

        exam.status = examStatus().EXAM_STATUS_PENDING;
        await pastExamServicePutPastExam(exam);
        // if (window.confirm(`Sınav onaya gönderildi!. Yeni sınav oluşturulsun mu?`)) {
        //     const archiveId = await getArchiveId(exam.academicYear, exam.examType, exam.semesterType);
        //     if (archiveId === undefined) {
        //         alert("Sınav Bulunamadı.");
        //     } else {
        //         try {
        //             const result = await createExamApi(
        //                 archiveId
        //             );
        //             navigate(`/exams/${result.toObject().exam.id}/edit`);
        //         } catch (error) {
        //             navigate(`/exams`);
        //         }
        //     }
        // } else {
        //     navigate(`/exams`);
        // }
    };

    const rate = fileInfo.width / (window.innerWidth / 12 * 6 * 0.9);


    const makeMarkContainerCss = (mark, rate) => {
        const resolutionRate = fileInfo.resolution / mark.resolution;
        return {
            width: mark.width / rate * resolutionRate,
            height: mark.height / rate * resolutionRate,
            backgroundColor: "#ff00008f",
            position: "absolute",
            top: mark.y / rate * resolutionRate,
            left: mark.x / rate * resolutionRate,
        }
    }
    const markTextCss = {
        margin: "0",
        padding: "0px 1px",
        fontSize: "0.em",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        background: "#ffffff",
        border: "1px solid",
        borderRadius: "8px"
    };



    const RenderContent = () => {
        if (loading)
            return <LoadingScreen />
        if (error !== null)
            <ErrorContent error={error} clickFunction={() => console.log("Tekrar Yükle")} />
        return <>

            <div className="row">
                <div className="col-3 overflow-scroll" style={{ height: '90vh' }}>
                    <table className="card-body table mb-0">
                        <tbody>
                            <tr>
                                <th scope="col">Alan:</th>
                                <td>{exam.area?.title ?? "-"}</td>
                            </tr>
                            <tr>
                                <th scope="col">Grup:</th>
                                <td>{exam.examGroup?.title ?? "-"}</td>
                            </tr>
                            <tr>
                                <th scope="col">Tip:</th>
                                <td>{exam.examType?.title ?? "-"}</td>
                            </tr>
                            <tr>
                                <th scope="col">Ders:</th>
                                <td>{exam.lesson?.title ?? "-"} ({exam.lesson?.code ?? "-"})</td>
                            </tr>
                            <tr>
                                <th scope="col">Akademik Yıl:</th>
                                <td>{academicYear(exam.academicYear ?? 0)}</td>
                            </tr>
                            <tr>
                                <th scope="col">Tarih:</th>
                                <td>{exam.date ? new Date(exam.date.seconds * 1000).toISOString().slice(0, -8).replace("T", " ") : "-"}</td>
                            </tr>
                            <tr>
                                <th scope="col">Cevap Anahtarı:</th>
                                <td >
                                    <table className="table table-striped">
                                        <tbody>
                                            {
                                                exam.questionsList.map((item, questionIndex) => {
                                                    return <tr key={`answer-key-${questionIndex}`}>
                                                        <th className="col-6 text-end" scope="row">{item.questionNumber}.</th>
                                                        <td className="col-6">{questionOption(item.correct)}</td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>

                    </table>
                </div>
                <div className="col-6 overflow-scroll" style={{ height: '90vh' }}>
                    {
                        fileInfo.pagesList.map((src, pageIndex) => {
                            return <div id={`page-${pageIndex}`} key={`page-${pageIndex}`} className="row">
                                <div className="col-12">
                                    <div style={{ width: fileInfo.width / rate, height: fileInfo.height / rate, backgroundImage: `url(${src})`, backgroundSize: "contain", position: "relative", margin: "auto" }} >{
                                        exam.questionsList.map((item, questionIndex) => {
                                            return <div key={`page-${pageIndex}-question-${questionIndex}`}>
                                                {
                                                    item.contentMark != undefined && pageIndex === item.contentMark.page - 1 ?
                                                        <div style={makeMarkContainerCss(item.contentMark, rate)}>
                                                            <p style={markTextCss}>{item.questionNumber}. soru</p>
                                                        </div> : <></>
                                                }
                                                {
                                                    item.hintMark != undefined && pageIndex === item.hintMark.page - 1 ? <div style={makeMarkContainerCss(item.hintMark, rate)}>
                                                        <p style={markTextCss}>{item.questionNumber}. ipucu</p>
                                                    </div> : <></>
                                                }
                                                {
                                                    item.infoMark != undefined && pageIndex === item.infoMark.page - 1 ? <div style={makeMarkContainerCss(item.infoMark, rate)}>
                                                        <p style={markTextCss}>{item.questionNumber}. bilgi</p>
                                                    </div> : <></>
                                                }
                                            </div>
                                        })
                                    }
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
                <div className="col-3 overflow-scroll" style={{ height: '90vh' }}>
                    <table className="table table-striped table-dark">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>SORU</th>
                                {/* <th>İPUCU</th> */}
                                {/* <th>BİLGİ</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {

                                exam.questionsList.map((item, questionIndex) => {




                                    return <tr key={`crop-question-${questionIndex}`}>
                                        <td>{item.questionNumber}</td>
                                        <td>
                                            {item.infoMark != undefined && item.infoMark.width > 0 && item.infoMark.height > 0 ? <><span className="badge text-bg-info mb-1">Bilgi</span><img className="mb-1" width={"100%"} src={`${process.env.REACT_APP_FMA_URL}/booklet/preview/questions/${item.id}/info.jpg`} /></> : ""}
                                            {item.contentMark != undefined && item.contentMark.width > 0 && item.contentMark.height > 0 ? <><span className="badge text-bg-danger mb-1">Soru</span><img className="mb-1" width={"100%"} src={`${process.env.REACT_APP_FMA_URL}/booklet/preview/questions/${item.id}/content.jpg`} /></> : ""}
                                            {item.hintMark != undefined && item.hintMark.width > 0 && item.hintMark.height > 0 ? <><span className="badge text-bg-success mb-1">İpucu</span><img className="mb-1" width={"100%"} src={`${process.env.REACT_APP_FMA_URL}/booklet/preview/questions/${item.id}/hint.jpg`} /></> : ""}
                                        </td>

                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>

            </div>
            <div className="row p-3" style={{ height: '10vh' }}>
                <div className="col-3 text-center">
                    <div className="btn-group btn-group-sm">
                        <Link to={"/exams/"} className="btn btn-outline-secondary" role="button">
                            SINAVLAR
                        </Link>
                        <Link to={previewPDF} target="_blank" className="btn btn-outline-secondary" role="button">
                            KİTAPÇIK
                        </Link>
                    </div>
                </div>
                <div className="col-6 text-center">


                    <div className="btn-group btn-group-sm">
                        <Link to={"/exams/" + exam.id + "/trim"} className="btn btn-outline-secondary" role="button">
                            KIRP
                        </Link>
                        {
                            authContext.isAdmin() ? <>
                                <button type="button" className="btn btn-outline-danger" role="button" onClick={() => {
                                    onPressChangeStatus(examStatus().EXAM_STATUS_TRASH);
                                }}>
                                    ÇÖP
                                </button>
                                <button type="button" className="btn btn-outline-warning" role="button" onClick={() => {
                                    onPressChangeStatus(examStatus().EXAM_STATUS_DRAFT);
                                }}>
                                    TASLAK
                                </button>

                                <button type="button" className="btn btn-outline-success" role="button" onClick={() => {
                                    onPressChangeStatus(examStatus().EXAM_STATUS_PUBLISH);
                                }}>
                                    YAYINLA
                                </button>
                            </> : <>
                                <button type="button" className="btn btn-outline-success" role="button" onClick={() => {
                                    onPressChangeStatus(examStatus().EXAM_STATUS_PENDING);
                                }}>
                                    ONAYLA
                                </button>

                                <button type="button" className="btn btn-outline-danger" role="button" onClick={() => {
                                    onPressChangeStatus(examStatus().EXAM_STATUS_FAILED);
                                }}>
                                    HATALI
                                </button>
                            </>
                        }
                    </div>
                    <div className="btn-group btn-group-sm float-end">
                        <Link onClick={() => {

                            const anchorEl = document.getElementById(`page-${exam.questionsList[0].contentMark.page - 1}`);
                            if (anchorEl) {
                                anchorEl.scrollIntoView();
                            }
                        }} className="btn btn-outline-secondary" role="button">
                            <i className="bi bi-arrow-bar-down"></i>
                        </Link>

                        <Link onClick={() => {
                            const anchorEl = document.getElementById(`page-${Object.keys(fileInfo.pagesList).length - 1}`);
                            if (anchorEl) {
                                anchorEl.scrollIntoView();
                            }
                        }} className="btn btn-outline-secondary float-end" role="button">
                            <i className="bi bi-arrow-down"></i>
                        </Link>
                    </div>

                </div>
            </div>
        </>
    }


    useEffect(() => {

        loadData();

    }, []);

    return <>
        <div className="container-fluid">
            {RenderContent()}
        </div>
    </>
}


export default ExamPreview;